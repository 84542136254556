import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Builder from "../components/builder"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const {
    biulder,
    metaDescription,
    metaTitle,
    metaImage,
  } = data.swapi.dataProgrammaticConsultingSingleton
  return (
    <Layout
      pageContext={pageContext}
      nameClass="data-programmatic-consulting servicesPage"
    >
      <SEO
        title={metaTitle}
        description={metaDescription}
        img={metaImage ? metaImage.path : ""}
        lang="pl"
      />
      <Builder data={biulder} pageContext={pageContext} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query dataProgrammaticConsultingPL {
    swapi {
      dataProgrammaticConsultingSingleton(lang: "pl") {
        biulder
        metaDescription
        metaTitle
        metaImage {
          path
        }
      }
    }
  }
`
